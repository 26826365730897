import React from 'react'
import { myStyles } from './style';
import Container from '@material-ui/core/Container';
import './PageNotFound.scss';
import { Link } from 'gatsby';
import clsx from 'clsx';

export default function PageNotFound({ data,basePath}) {
const { ctaButton, header, subHeader } = data;
const classes = myStyles(data);

  return (
    <div className={clsx('page-not-found', classes['page-not-found'])}>
    <Container maxWidth="xl">
      <h1 locId="header">{header}</h1>
      <h3 locId="subHeader">{subHeader}</h3>
      <Link locId="ctaButton" to= {`/${basePath}` + ctaButton?.link?.slug} className="home_btn">
        {ctaButton?.label}
      </Link>
    </Container>
  </div>
  )
}